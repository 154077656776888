import classNames from "classnames";
import { useEffect } from "react";
import { useSelector } from "react-redux";

import Feedback from "components/Feedback";
import Login from "components/pages/Login";
import useAnalytics from "hooks/useAnalytics";
import { loginSelector } from "store/slices/login";
import { ESQUEMA_OSCURO, opcionesSelector } from "store/slices/opciones";

import "./Root.css";
import imagenes from "./preloads";

const Root = () => {
  const { token } = useSelector(loginSelector);
  const { esquema } = useSelector(opcionesSelector);
  const track = useAnalytics();

  useEffect(() => {
    window.addEventListener("focus", () =>
      track("Feedback", "Browser", "focus"),
    );
    window.addEventListener("blur", () => track("Feedback", "Browser", "blur"));
    document.addEventListener("copy", () =>
      track("Feedback", "Browser", "copy", {
        texto: document.getSelection()?.toString(),
      }),
    );
    if (
      window.performance
        .getEntriesByType("navigation")
        .map((nav) => (nav as PerformanceNavigationTiming).type)
        .includes("reload")
    ) {
      track("Feedback", "Browser", "refresh");
    }
  }, [track]);

  return (
    <div
      className={classNames({
        Root: true,
        Root__oscura: esquema === ESQUEMA_OSCURO,
      })}
    >
      {token ? <Feedback /> : <Login />}
      <div className="Root__preload_imagenes">
        {imagenes.map((img, i) => (
          <img
            key={`preload-img-${i}`}
            src={img}
            alt={`preload-${i}`}
            fetchPriority="low"
          />
        ))}
      </div>
    </div>
  );
};

export default Root;
